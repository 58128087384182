import { UserDataContext } from "context/UserDataContext";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const covers = ["nocover.jpg", "concert-2527495_1280.jpg", "dj-4702977_1280.jpg", "guitar-2276181_1280.jpg", "music-3569417_1280.jpg", "sunrise-1014712_1280.jpg"];

// Playlist-Hinzufügen-Seite
const AudioBrowserAddPlaylistPage = () => {
  const navigate = useNavigate();
  const { handleAddPlaylist } = useContext(UserDataContext);
  const [selectedCover, setSelectedCover] = useState(covers[0]);
  const [playlistName, setPlaylistName] = useState("");
  const [playlistColor, setPlaylistColor] = useState("#ffffff");

  const handleCoverClick = (cover) => {
    setSelectedCover(cover);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleAddPlaylist(playlistName, selectedCover, playlistColor);
    navigate("/gespeichert");
  };

  return (
    <>
      <h1>Playlist hinzufügen</h1>
      <form onSubmit={handleSubmit}>
        
        <p>Wählen Sie ein Cover aus:</p>

        <div className="cover-wrapper">
          {covers.map((cover) => (
            <div key={cover} onClick={() => handleCoverClick(cover)} className="cover-container" style={selectedCover === cover ? { border: "2px solid white" } : {}}>
              <img src={`/files/images/${cover}`} alt={cover} className="cover-image" />
              <div style={{ backgroundColor: playlistColor }} className="cover-overlay"></div>
            </div>
          ))}
        </div>

        <div>
          <p>Wählen Sie eine Cover-Farbe aus:</p>
          <input type="color" required value={playlistColor} onChange={(e) => setPlaylistColor(e.target.value)} />
        </div>
        <p>Geben Sie den Namen der Playlist ein:</p>

        <div>
          <input title="Der Playlist-Name muss mit einem Großbuchstaben beginnen und mindestens 3 Zeichen und maximal 12 Zeichen lang sein." pattern="[A-Z0-9].{2,20}" 
          type="text" 
          value={playlistName} 
          onChange={(e) => setPlaylistName(e.target.value)} 
          required />
        </div>

        <button type="submit">Playlist hinzufügen</button>
      </form>
    </>
  );
};

export default AudioBrowserAddPlaylistPage;
