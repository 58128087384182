import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { playlists, songs } from "data/data";
import AudioBrowserSongList from "../SubComponents/AudioBrowserSongList";
import { UserDataContext } from "context/UserDataContext";
import NoEntryMessage from "../SubComponents/NoEntryMessage";
import { ContextMenuContext } from "components/GlobalContextMenu/GlobalContextMenu";
import { formatSecondsToTimecode, sumPlaylistPlaytime } from "lib/helpers";

// Playlist-Seite
const AudioBrowserPlaylistPage = () => {
  const navigate = useNavigate();
  const { playlistid } = useParams();
  const [playlistSongs, setPlaylistSongs] = useState([]);
  const [playlistData, setPlaylistData] = useState({});
  const [playlistPlayTime, setPlaylistPlayTime] = useState();
  const { personalPlaylists } = useContext(UserDataContext);
  const { handleOpenContextMenu } = useContext(ContextMenuContext);

  // Sucht mithilfe der "playlistid" die Playlist aus der "data.js" und erstellt ein Song-Objekt Array 
  useEffect(() => {
    const joinedPlaylists = playlists.list.concat(personalPlaylists);
    const activePlaylist = joinedPlaylists.find((pl) => pl.id === Number(playlistid));

    if (!activePlaylist) {
      navigate("/gespeichert");

      handleOpenContextMenu({
        heading: "Die gesuchte Playlist existiert nicht mehr.",
        description: "Die Playlist wurde gelöscht oder konnte nicht gefunden werden.",
        agreeText: "Weiter",
        type: "simple",
      });
      return;
    }

    const songList = [];
    for (let i = 0; i < activePlaylist.list.length; i++) {
      const songId = activePlaylist.list[i];
      const found = songs.list.find((song) => song.id === songId);
      if (found) {
        songList.push(found);
      }
    }

    setPlaylistData(activePlaylist);
    setPlaylistSongs(songList);
  }, [playlistid, personalPlaylists]);

  // Berechnen der Playlistdauer
  useEffect(() => {
    let playlistPlaytime = sumPlaylistPlaytime(playlistSongs);
    playlistPlaytime = formatSecondsToTimecode(playlistPlaytime);
    setPlaylistPlayTime(playlistPlaytime);
  }, [playlistSongs]);

  return (
    <>
      <div className="audio-browser_intro-header">
        <div className="cover-image__container">
          <img className="cover-image__img" src={playlistData?.cover ? `/files/images/${playlistData?.cover}` : `/files/images/nocover.jpg`} />
          <div className="cover-image__color-overlay" style={{ background: playlistData?.color }}></div>
        </div>
        <div>
          <h1 className="no-margin">{playlistData?.name}</h1>
          <p className="audio-browser__playlist-type">{playlistData?.privat ? "Eigene Playlist" : "Vordefinierte Playlist"}</p>
          <p className="">{playlistSongs.length ? `Songs in der Playlist: ${playlistSongs.length}` : "Diese Playlist hat noch keine Songs."}</p>
          <p>Playlistlaufzeit: {playlistPlayTime}</p>
          <p className="audio-browser_description"></p>
        </div>
      </div>
      
      <p style={{ color: "grey", fontSize: "0.8em", maxWidth: 500 }}>Hinweis: Alle Bild-, Icon- und Musikwerke der Webanwendung wurden nicht von dem Autor dieser Arbeit erstellt und sind frei nutzbare Werke von Pixabay.com  und icon-monstr.com. Die Song-Cover wurden nachträglich hinzugefügt und sind nicht vom ursprünglichen Musik-Urheber ausgewählt worden. Eine Übersicht der Musik-, Icon- und Coverurheber sowie der Lizenztypen befindet sich unter dem Reiter Impressum. Außerdem erfolte die Zuweisung eines Genres im nachhinhein. Eine Auflistung finden Sie im Impressum.</p>

      {playlistSongs.length > 0 ? (
        <AudioBrowserSongList 
        playlistData={playlistData} 
        playlistSongs={playlistSongs}>
        </AudioBrowserSongList>
      ) : (
        <NoEntryMessage>
          <h3 className="no-margin">Keine Einträge gefunden.</h3>
          <p>Fügen Sie zunächst Songs hinzu, um diese Playlist nutzen zu können.</p>
          <Link to={"/playlisten"}>
            <p className="no-margin">Songs entdecken.</p>
          </Link>
        </NoEntryMessage>
      )}
    </>
  );
};

export default AudioBrowserPlaylistPage;
