export const formatSecondsToTimecode = (seconds) => {
  let minutes = Math.floor(seconds / 60);
  let remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
};

export const sumPlaylistPlaytime = (playlist) => {
  let sum = 0;
  for (let i = 0; i < playlist.length; i++) {
    if (typeof playlist[i].songdauer === "number") {
      sum += playlist[i].songdauer;
    }
  }
  return sum;
};
