import React, { useState, useEffect, useContext } from "react";
import { AudioContext } from "components/AudioPlayer/AudioPlayer";
import SongListItem from "./SongListItem";
import SongListColumnHeading from "./SongListColumnHeading";

// Komponente zum Ausgeben von Songs
const AudioBrowserSongList = ({ playlistData, playlistSongs }) => {
  const [sortField, setSortField] = useState("Nr.");
  const [sortAsc, setSortAsc] = useState(true);
  const [sortedSongs, setSortedSongs] = useState([]);
  const { currentSongOrigin, handleCurrentPlaylistUpdate } = useContext(AudioContext);

  // Die "playlistSongs" werden  nummeriert und sortiert
  useEffect(() => {
    let playlistWithPositions = playlistSongs.map((song, index) => {
      return { ...song, playlistIndex: index + 1 };
    });

    let newSortedPlaylist = sortPlaylist(playlistWithPositions);

    setSortedSongs([...newSortedPlaylist]);
  }, [playlistSongs]);

  // Die "playlistSongs" werden sortiert
  useEffect(() => {
    const sortedPlaylist = sortPlaylist(sortedSongs);

    if (sortedSongs.length > 0) {
      setSortedSongs(sortedPlaylist);
    }
  }, [sortField, sortAsc]);
  
  // Wenn der aktuell abgespielte Song in dieser Playlist ist, 
  // wird die aktuelle Abspielplaylist aktualisiert
  useEffect(() => {
    if (playlistData?.id == currentSongOrigin?.id) {
      handleCurrentPlaylistUpdate(sortedSongs);
    }
  }, [sortedSongs]);

  const sortPlaylist = (playlist) => {
    if (sortField !== null) {
      const sortedList = [...playlist].sort((a, b) => {
        switch (sortField) {
          case "Songtitel":
            return sortAsc ? a.titel.localeCompare(b.titel) : b.titel.localeCompare(a.titel);
          case "Interpret":
            return sortAsc ? a.interpret.localeCompare(b.interpret) : b.interpret.localeCompare(a.interpret);
          case "Songdauer":
            return sortAsc ? a.songdauer - b.songdauer : b.songdauer - a.songdauer;
          case "Genre":
            return sortAsc ? a.genre.localeCompare(b.genre) : b.genre.localeCompare(a.genre);
          case "Nr.":
            return sortAsc ? a.playlistIndex - b.playlistIndex : b.playlistIndex - a.playlistIndex;
          default:
            return 0;
        }
      });

      return sortedList;
    }
  };

  const handleSort = (field) => {
    setSortAsc(field === sortField ? !sortAsc : true);
    setSortField(field);
  };

  return (
    <div className="song-playlist">
      <div className="song-list">
        <div className="song-item header">
          <div className="song-item__text song__Controls">
            <span className="song-item__span"></span>Sortieren:
          </div>
          <div className="song-item__text song__Cover">
            <span className="song-item__span">Cover</span>
          </div>
          <SongListColumnHeading sortAsc={sortAsc} handleSort={handleSort} sortField={sortField} sortAfter={"Songtitel"} />
          <SongListColumnHeading sortAsc={sortAsc} handleSort={handleSort} sortField={sortField} sortAfter={"Interpret"} />
          <SongListColumnHeading sortAsc={sortAsc} handleSort={handleSort} sortField={sortField} sortAfter={"Songdauer"} />
          <SongListColumnHeading sortAsc={sortAsc} handleSort={handleSort} sortField={sortField} sortAfter={"Genre"} />
          <SongListColumnHeading sortAsc={sortAsc} handleSort={handleSort} sortField={sortField} sortAfter={"Nr."} />
        </div>
        {sortedSongs.map((song, index) => (
          <SongListItem 
          song={song} 
          positionIndex={index} 
          playlistData={playlistData} 
          activePlaylist={sortedSongs} 
          key={index}>
          </SongListItem>
        ))}
      </div>
    </div>
  );
};

export default AudioBrowserSongList;
