import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "context/UserDataContext";
import AudioBrowserPlaylistList from "../SubComponents/AudioBrowserPlaylistList";
import NoEntryMessage from "../SubComponents/NoEntryMessage";

// Eigene-Playlisten-Seite
const AudioBrowserPersonalPlaylistsPage = () => {
  const navigate = useNavigate();
  const { personalPlaylists } = useContext(UserDataContext);

  return (
    <>
      <div>
        <h1>Eigene Playlisten</h1>
        <p className="audio-browser_description">Hier befinden sich ihre persönlichen Playlisten. Erstellen Sie neue Playlisten oder schauen Sie sich die bestehenden Playlisten an. Die Playlisten bleiben auch bei einem Browser neustart bestehen.</p>

        <button
          onClick={() => {
            navigate("/playlist-hinzufuegen");
          }}
        >
          + Neue Playlist erstellen
        </button>

      </div>
      {personalPlaylists.length > 0 ? (
        <AudioBrowserPlaylistList playlisten={personalPlaylists} />
      ) : (
        <NoEntryMessage>
          <h3 className="no-margin">Keine Einträge gefunden.</h3>
          <p>Fügen Sie zunächst eine Playlist hinzu.</p>
        </NoEntryMessage>
      )}
    </>
  );
};

export default AudioBrowserPersonalPlaylistsPage;
