import AudioPlayer from "components/AudioPlayer/AudioPlayer";
import UserDataProvider from "./context/UserDataContext";
import GlobalContextMenu from "components/GlobalContextMenu/GlobalContextMenu";
import { BrowserRouter } from "react-router-dom";

// Einstiegspunkt der AudioPlayer Webanwendung
const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <UserDataProvider>
          <GlobalContextMenu>
            <AudioPlayer></AudioPlayer>
          </GlobalContextMenu>
        </UserDataProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
