import { useContext } from "react";
import { AudioContext } from "../AudioPlayer";
import AudioControlTimeline from "./AudioControlTimeline";

// Steuerungseinheit für den AudioPlayer
const AudioControl = () => {
  const { handleNavigate, currentSong, isPlaying, volume, handleVolumeChange, handlePlayPause } = useContext(AudioContext);

  return (
    <div className="audio-controls">
      <div className="audio-controls__left">
        <img alt="" className="song-info__img" src={currentSong?.cover ? `/files/covers/${currentSong.cover}` : "/files/images/nocover.jpg"}></img>
        <div className="song-info__text">
          <div>{currentSong?.titel ? currentSong.titel : "Kein Titel ausgewählt."}</div>
          <div>{currentSong?.interpret ? currentSong.interpret : ""}</div>
        </div>
      </div>
      <div className="audio-controls__mid">
        <div className="audio-controls__button-container">
          <div
            className="audio-controls__button"
            onClick={() => {
              handleNavigate("back");
            }}
          >
            {lastButton}
          </div>
          <div className="audio-controls__button" onClick={handlePlayPause}>
            {isPlaying ? pauseButton : playButton}
          </div>
          <div
            className="audio-controls__button"
            onClick={() => {
              handleNavigate("next");
            }}
          >
            {nextButton}
          </div>
        </div>
        <AudioControlTimeline></AudioControlTimeline>
      </div>
      <div className="audio-controls__right">
        <input className="volume-control" type="range" min="0" max="1" step="0.01" value={volume} onChange={handleVolumeChange} />
      </div>
    </div>
  );
};

export default AudioControl;

const playButton = (
  <div className="button-container__inner">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="white">
      <path d="M3 22v-20l18 10-18 10z" />
    </svg>
  </div>
);

const pauseButton = (
  <div className="button-container__inner">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="white">
      <path d="M11 22h-4v-20h4v20zm6-20h-4v20h4v-20z" />
    </svg>
  </div>
);

const nextButton = (
  <div className="button-container__inner">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="grey">
      <path d="M20 22v-20h2v20h-2zm-18 0l16-10-16-10v20z" />
    </svg>
  </div>
);

const lastButton = (
  <div className="button-container__inner">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="grey">
      <path d="M4 2v20h-2v-20h2zm18 0l-16 10 16 10v-20z" />
    </svg>
  </div>
);
