// Alle Einträge sind Lizenzfreie Werke von Pixabay.com

//  Vorgesehene Definition von Playlist- und Songobjekten

// const songObject = {
//   titel: "Songtitel",
//   interpret: "Songinterpret",
//   dateipfad: "Songpfad.mp3",
//   genre: "Songgenre",
//   cover: "Songcover.jpg",
//   songdauer: 123,
//   id: 1234,
// };

// const playlistObject = {
//   name: "Playlistname",
//   cover: "Playlistcover.jpg",
//   color: "#ff00ff",
//   list: [1001, 1003, 1007, 1004, 1032],
//   id: 1001,
// };

export const songs = {
  list: [
    {
      titel: "Easy Lifestyle",
      interpret: "Lesfm",
      dateipfad: "easy-lifestyle-137766.mp3",
      genre: "Akustik\r",
      cover: "2836300.jpg",
      songdauer: 165,
      id: 1000,
      url: "https://pixabay.com/de/music/akustische-gruppe-easy-lifestyle-137766/",
    },
    {
      titel: "The Blackest Bouquet",
      interpret: "LeonellCassio",
      dateipfad: "leonell-cassio-the-blackest-bouquet-118766.mp3",
      genre: "Elektronik\r",
      cover: "1868612.jpg",
      songdauer: 221,
      id: 1001,
      url: "https://pixabay.com/de/music/zukunftiger-bass-leonell-cassio-the-blackest-bouquet-118766/",
    },
    {
      titel: "Powerful Beat",
      interpret: "penguinmusic",
      dateipfad: "powerful-beat-121791.mp3",
      genre: "Elektronik\r",
      cover: "5046876.jpg",
      songdauer: 73,
      id: 1002,
      url: "https://pixabay.com/de/music/zukunftiger-bass-powerful-beat-121791/",
    },
    {
      titel: "Password Infinity",
      interpret: "Evgeny_Bardyuzha",
      dateipfad: "password-infinity-123276.mp3",
      genre: "Elektronik\r",
      cover: "11107.jpg",
      songdauer: 145,
      id: 1003,
      url: "https://pixabay.com/de/music/schlagt-password-infinity-123276/",
    },
    {
      titel: "Electronic Future Beats",
      interpret: "QubeSounds",
      dateipfad: "electronic-future-beats-117997.mp3",
      genre: "Elektronik\r",
      cover: "",
      songdauer: 126,
      id: 1004,
      url: "https://pixabay.com/de/music/zukunftiger-bass-electronic-future-beats-117997/",
    },
    {
      titel: "Awaken",
      interpret: "Onoychenkomusic",
      dateipfad: "awaken-136824.mp3",
      genre: "Film\r",
      cover: "3206530.jpg",
      songdauer: 180,
      id: 1005,
      url: "https://pixabay.com/de/music/uberschrift-awaken-136824/",
    },
    {
      titel: "Waterfall",
      interpret: "RomanSenykMusic",
      dateipfad: "waterfall-140894.mp3",
      genre: "Film\r",
      cover: "",
      songdauer: 164,
      id: 1006,
      url: "https://pixabay.com/de/music/abenteuer-waterfall-140894/",
    },
    {
      titel: "Cinematic Documentary",
      interpret: "Lexin_Music",
      dateipfad: "cinematic-documentary-115669.mp3",
      genre: "Film\r",
      cover: "2836300.jpg",
      songdauer: 131,
      id: 1007,
      url: "https://pixabay.com/de/music/schone-stucke-cinematic-documentary-115669/",
    },
    {
      titel: "Cinematic Atmosphere Score 2",
      interpret: "Musictown",
      dateipfad: "cinematic-atmosphere-score-2-22136.mp3",
      genre: "Ambient\r",
      cover: "2695569.jpg",
      songdauer: 177,
      id: 1008,
      url: "https://pixabay.com/de/music/umgebungs-cinematic-atmosphere-score-2-22136/",
    },
    {
      titel: "Cinematic Time Lapse",
      interpret: "Lexin_Music",
      dateipfad: "cinematic-time-lapse-115672.mp3",
      genre: "Film\r",
      cover: "",
      songdauer: 121,
      id: 1009,
      url: "https://pixabay.com/de/music/uberschrift-cinematic-time-lapse-115672/",
    },
    {
      titel: "Into The Night",
      interpret: "prazkhanal",
      dateipfad: "into-the-night-20928.mp3",
      genre: "Hip-Hop\r",
      cover: "1867616.jpg",
      songdauer: 140,
      id: 1010,
      url: "https://pixabay.com/de/music/schlagt-into-the-night-20928/",
    },
    {
      titel: "Floating Abstract",
      interpret: "ComaStudio",
      dateipfad: "floating-abstract-142819.mp3",
      genre: "Elektronik\r",
      cover: "3608029.jpg",
      songdauer: 97,
      id: 1011,
      url: "https://pixabay.com/de/music/schlagt-floating-abstract-142819/",
    },
    {
      titel: "Soft ambient",
      interpret: "Alex_MakeMusic",
      dateipfad: "soft-ambient-10782.mp3",
      genre: "Ambient\r",
      cover: "2040340.jpg",
      songdauer: 192,
      id: 1012,
      url: "https://pixabay.com/de/music/moderne-klassische-soft-ambient-10782/",
    },
    {
      titel: "Lifelike",
      interpret: "AlexiAction",
      dateipfad: "lifelike-126735.mp3",
      genre: "Elektronik\r",
      cover: "1844798.jpg",
      songdauer: 144,
      id: 1013,
      url: "https://pixabay.com/de/music/jovo-basszusgitar-lifelike-126735/",
    },
    {
      titel: "Funk Upbeat",
      interpret: "SigmaMusicArt",
      dateipfad: "funk-upbeat-157134.mp3",
      genre: "Funk\r",
      cover: "",
      songdauer: 112,
      id: 1014,
      url: "https://pixabay.com/de/music/funk-funk-upbeat-157134/",
    },
    {
      titel: "Energetic Funky Groove",
      interpret: "Onoychenkomusic",
      dateipfad: "energetic-funky-groove-138634.mp3",
      genre: "Funk\r",
      cover: "4118588.jpg",
      songdauer: 107,
      id: 1015,
      url: "https://pixabay.com/de/music/funk-energetic-funky-groove-138634/",
    },
    {
      titel: "That Funk Show",
      interpret: "Diamond_Tunes",
      dateipfad: "that-funk-show-20691.mp3",
      genre: "Funk\r",
      cover: "",
      songdauer: 101,
      id: 1016,
      url: "https://pixabay.com/de/music/optimistisch-that-funk-show-20691/",
    },
    {
      titel: "Fashion Pop Groove",
      interpret: "PaulYudin",
      dateipfad: "fashion-pop-groove-155324.mp3",
      genre: "Funk\r",
      cover: "388989.jpg",
      songdauer: 152,
      id: 1017,
      url: "https://pixabay.com/de/music/funk-fashion-pop-groove-155324/",
    },
    {
      titel: "Moving Slowly (80s Retro Music)",
      interpret: "Music_Unlimited",
      dateipfad: "moving-slowly-80s-retro-music-121571.mp3",
      genre: "Funk\r",
      cover: "",
      songdauer: 154,
      id: 1018,
      url: "https://pixabay.com/de/music/funk-moving-slowly-80s-retro-music-121571/",
    },
    {
      titel: "Enjoy the Island Reggae",
      interpret: "Musictown",
      dateipfad: "enjoy-the-island-reggae-113295.mp3",
      genre: "Reggae\r",
      cover: "3021072.jpg",
      songdauer: 160,
      id: 1019,
      url: "https://pixabay.com/de/music/reggae-enjoy-the-island-reggae-113295/",
    },
    {
      titel: "Reggae Reggae",
      interpret: "JuliusH",
      dateipfad: "reggae-reggae-2039.mp3",
      genre: "Reggae\r",
      cover: "",
      songdauer: 134,
      id: 1020,
      url: "https://pixabay.com/de/music/reggae-reggae-reggae-2039/",
    },
    {
      titel: "Reggae_loop",
      interpret: "sonicwip",
      dateipfad: "reggae_loop-129824.mp3",
      genre: "Reggae\r",
      cover: "2836300.jpg",
      songdauer: 56,
      id: 1021,
      url: "https://pixabay.com/de/music/reggae-reggae-loop-129824/",
    },
    {
      titel: "A Documentary",
      interpret: "AKTASOK",
      dateipfad: "a-documentary-157067.mp3",
      genre: "Piano\r",
      cover: "1239729.jpg",
      songdauer: 154,
      id: 1022,
      url: "https://pixabay.com/de/music/moderne-klassische-a-documentary-157067/",
    },
    {
      titel: "Soft Inspirational Piano",
      interpret: "The_Mountain",
      dateipfad: "soft-inspirational-piano-153643.mp3",
      genre: "Piano\r",
      cover: "1655558.jpg",
      songdauer: 168,
      id: 1023,
      url: "https://pixabay.com/de/music/uberschrift-soft-inspirational-piano-153643/",
    },
    {
      titel: "Old Madrid",
      interpret: "PianoAmor",
      dateipfad: "old-madrid-153746.mp3",
      genre: "Piano\r",
      cover: "",
      songdauer: 140,
      id: 1024,
      url: "https://pixabay.com/de/music/moderne-klassische-old-madrid-153746/",
    },
    {
      titel: "Unio Mystica",
      interpret: "SamuelFrancisJohnson",
      dateipfad: "unio-mystica-156380.mp3",
      genre: "Piano\r",
      cover: "",
      songdauer: 42,
      id: 1025,
      url: "https://pixabay.com/de/music/umgebungs-unio-mystica-156380/",
    },
    {
      titel: "Acoustic Folk Music Guitar",
      interpret: "ArtMusic",
      dateipfad: "acoustic-folk-music-guitar-141345.mp3",
      genre: "Akustik\r",
      cover: "3090204.jpg",
      songdauer: 100,
      id: 1026,
      url: "https://pixabay.com/de/music/akustische-gruppe-acoustic-folk-music-guitar-141345/",
    },
    {
      titel: "Morning Walk",
      interpret: "Lesfm",
      dateipfad: "morning-walk-154578.mp3",
      genre: "Akustik\r",
      cover: "4945407.jpg",
      songdauer: 104,
      id: 1027,
      url: "https://pixabay.com/de/music/solo-gitarre-morning-walk-154578/",
    },
    {
      titel: "Crossroads",
      interpret: "SergePavkinMusic",
      dateipfad: "crossroads-154401.mp3",
      genre: "Rock\r",
      cover: "756326.jpg",
      songdauer: 192,
      id: 1028,
      url: "https://pixabay.com/de/music/indie-pop-crossroads-154401/",
    },
    {
      titel: "Urban Go",
      interpret: "lemonmusicstudio",
      dateipfad: "urban-go-132912.mp3",
      genre: "Rock\r",
      cover: "1658887.jpg",
      songdauer: 160,
      id: 1029,
      url: "https://pixabay.com/de/music/indie-pop-urban-go-132912/",
    },
    {
      titel: "Good Morning",
      interpret: "RomanSenykMusic",
      dateipfad: "good-morning-150836.mp3",
      genre: "Rock\r",
      cover: "3206530.jpg",
      songdauer: 190,
      id: 1030,
      url: "https://pixabay.com/de/music/indie-pop-good-morning-150836/",
    },
    {
      titel: "Midnight Blues",
      interpret: "lemonmusicstudio",
      dateipfad: "midnight-blues-21179.mp3",
      genre: "Rock\r",
      cover: "",
      songdauer: 139,
      id: 1031,
      url: "https://pixabay.com/de/music/moderner-blues-midnight-blues-21179/",
    },
    {
      titel: "Crazy Party",
      interpret: "lemonmusicstudio",
      dateipfad: "crazy-party-124090.mp3",
      genre: "Rock\r",
      cover: "2527495.jpg",
      songdauer: 126,
      id: 1032,
      url: "https://pixabay.com/de/music/felsen-crazy-party-124090/",
    },
    {
      titel: "Sweet Dreams",
      interpret: "RelaxingTime",
      dateipfad: "sweet-dreams-152663.mp3",
      genre: "Ambient\r",
      cover: "7583415.jpg",
      songdauer: 943,
      id: 1033,
      url: "https://pixabay.com/de/music/solo-klavier-sweet-dreams-152663/",
    },
    {
      titel: "Epic Relaxing Flute Music",
      interpret: "shakibhasansayed",
      dateipfad: "epic-relaxing-flute-music-144009.mp3",
      genre: "Ambient\r",
      cover: "3090204.jpg",
      songdauer: 292,
      id: 1034,
      url: "https://pixabay.com/de/music/umgebungs-epic-relaxing-flute-music-144009/",
    },
    {
      titel: "Meditation Breeze",
      interpret: "Grand_Project",
      dateipfad: "meditation-breeze-145529.mp3",
      genre: "Ambient\r",
      cover: "3024434.jpg",
      songdauer: 600,
      id: 1035,
      url: "https://pixabay.com/de/music/meditation-spirituell-meditation-breeze-145529/",
    },
    {
      titel: "Energetic Hip-Hop",
      interpret: "Skilsel",
      dateipfad: "energetic-hip-hop-8303.mp3",
      genre: "Hip-Hop\r",
      cover: "",
      songdauer: 114,
      id: 1036,
      url: "https://pixabay.com/de/music/schlagt-energetic-hip-hop-8303/",
    },
    {
      titel: "Classical Therapy",
      interpret: "moodmode",
      dateipfad: "classical-therapy-156145.mp3",
      genre: "Hip-Hop\r",
      cover: "5046876.jpg",
      songdauer: 113,
      id: 1037,
      url: "https://pixabay.com/de/music/schlagt-classical-therapy-156145/",
    },
    {
      titel: "Urban Asphalt",
      interpret: "moodmode",
      dateipfad: "urban-asphalt-155598.mp3",
      genre: "Hip-Hop\r",
      cover: "1867121.jpg",
      songdauer: 106,
      id: 1038,
      url: "https://pixabay.com/de/music/schlagt-urban-asphalt-155598/",
    },
    {
      titel: "Hip Hop Vlog",
      interpret: "Music_Unlimited",
      dateipfad: "hip-hop-vlog-120281.mp3",
      genre: "Hip-Hop\r",
      cover: "",
      songdauer: 110,
      id: 1039,
      url: "https://pixabay.com/de/music/schlagt-hip-hop-vlog-120281/",
    },
    {
      titel: "Fashion Hip-Hop",
      interpret: "Music_Unlimited",
      dateipfad: "fashion-hip-hop-113774.mp3",
      genre: "Hip-Hop\r",
      cover: "1868612.jpg",
      songdauer: 206,
      id: 1040,
      url: "https://pixabay.com/de/music/schlagt-fashion-hip-hop-113774/",
    },
    {
      titel: "Fast Jazz",
      interpret: "WaveMaster",
      dateipfad: "fast-jazz-143910.mp3",
      genre: "Jazz\r",
      cover: "",
      songdauer: 108,
      id: 1041,
      url: "https://pixabay.com/de/music/traditioneller-jazz-fast-jazz-143910/",
    },
    {
      titel: "Happy Swing",
      interpret: "SergeQuadrado",
      dateipfad: "happy-swing-20937.mp3",
      genre: "Jazz\r",
      cover: "",
      songdauer: 130,
      id: 1042,
      url: "https://pixabay.com/de/music/traditioneller-jazz-happy-swing-20937/",
    },
    {
      titel: "Cool Piano Jazz",
      interpret: "JuliusH",
      dateipfad: "cool-piano-jazz-2642.mp3",
      genre: "Jazz\r",
      cover: "1655558.jpg",
      songdauer: 148,
      id: 1043,
      url: "https://pixabay.com/de/music/traditioneller-jazz-cool-piano-jazz-2642/",
    },
    {
      titel: "Light Jazz",
      interpret: "WaveMaster",
      dateipfad: "light-jazz-145050.mp3",
      genre: "Jazz\r",
      cover: "4118588.jpg",
      songdauer: 96,
      id: 1044,
      url: "https://pixabay.com/de/music/traditioneller-jazz-light-jazz-145050/",
    },
    {
      titel: "feel so good",
      interpret: "Darockart",
      dateipfad: "feel-so-good-143888.mp3",
      genre: "Jazz",
      cover: "",
      songdauer: 131,
      id: 1045,
      url: "https://pixabay.com/de/music/traditioneller-jazz-feel-so-good-143888/",
    },
  ],
};

export const playlists = {
  list: [
    { id: 1001, name: "Powerful", cover: "concert-2527495_1280.jpg", list: [1032, 1002, 1036, 1039, 1032] },
    { id: 1002, name: "Roadtrip", cover: "guitar-2276181_1280.jpg", list: [1028, 1029, 1036, 1002], color: "#FFA800" },
    { id: 1003, name: "Relax", cover: "sunrise-1014712_1280.jpg", list: [1035, 1012, 1044, 1043], color: "#31ACA6" },
    { id: 1005, name: "Hip-Hop", color: "#ff0000", cover: "dj-4702977_1280.jpg", list: [1010, 1036, 1038, 1039, 1040] },
    { id: 1006, name: "Ambient", color: "#0065A1", cover: "sunrise-1014712_1280.jpg", list: [1008, 1012, 1033, 1034, 1035] },
    { id: 1007, name: "Elektronik", cover: "music-3569417_1280.jpg", list: [1001, 1002, 1003, 1013] },
    { id: 1008, name: "Rock", color: "#00ff99", cover: "guitar-2276181_1280.jpg", list: [1028, 1029, 1030, 1031, 1032] },
    { id: 1009, name: "Jazz", cover: "", list: [1041, 1042, 1043, 1044, 1045] },
  ],
};
