import React, { createContext, useState, useRef, useEffect } from "react";

// vorgesehener Aufbau eines menuSettingsObject-Objektes
// const menuSettingsObject = {
//   // ---pflicht---
//   heading: "Song hinzufügen",
//   description: "Wählen Sie eine Playlist aus die den Song hinzugefügt bekommen soll.",
//   cancelText: "Abbrechen",
//   agreeText: "Song hinzufügen",
//   agreeFunction: () => {
//     runcode(agreeFunctionParams);
//   },
//   // ---optional---
//   agreeFunctionParams: [song?.id],
//   selectOptions: [
//     { option: "Erste Option", value: 100 },
//     { option: "Zweite Option", value: 1002 },
//   ],
//   selectOptionsParamPosition: 1,
//   type: "select",
// };

export const ContextMenuContext = createContext();

const GlobalContextMenu = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [menuSettings, setMenuSettings] = useState({});

  const handleCloseContextMenu = () => {
    setIsVisible(false);
    setMenuSettings({});
  };

  const handleOpenContextMenu = (settings) => {
    setMenuSettings(settings);
    setIsVisible(true);
  };


  return (
    <>
      {isVisible && (
        <div className="globalcontextmenu">
          <div className="globalcontextmenu__background"></div>
          <div className="globalcontextmenu__container">
            <GlobalContextMenuContent
             handleCloseContextMenu={handleCloseContextMenu}
             menuSettings={menuSettings} />
          </div>
        </div>
      )}
      <ContextMenuContext.Provider 
      value={{ handleOpenContextMenu }}>
        {children}
      </ContextMenuContext.Provider>
    </>
  );
};

export default GlobalContextMenu;

const GlobalContextMenuContent = ({ handleCloseContextMenu, menuSettings }) => {
  const [generatedParamList, setGeneratedParamList] = useState([]);
  const [inputValue, setInputValue] = useState();

  useEffect(() => {
    if (menuSettings.type == "select") {
      setInputValue(menuSettings.selectOptions[0].value);
    }
  }, [menuSettings]);

  useEffect(() => {
    const generatedList = generateParamList(menuSettings.agreeFunctionParams, menuSettings.selectOptionsParamPosition, inputValue);

    setGeneratedParamList(generatedList);
  }, [menuSettings, inputValue]);

  const handleClickOnAgree = () => {
    handleCloseContextMenu();

    if (menuSettings?.agreeFunction) {
      menuSettings.agreeFunction(...generatedParamList);
    }
  };

  const generateParamList = (params, position, inputValue) => {
    if (menuSettings.type == "select") {
      const paramsArray = [...params];
      paramsArray.splice(position, 0, inputValue);
      return paramsArray;
    } else {
      return params;
    }
  };

  return (
    <>
      <h1 className="no-margin">{menuSettings?.heading}</h1>
      <p>{menuSettings?.description}</p>

      {menuSettings?.type == "select" && (
        <select
          onChange={(event) => {
            setInputValue(event.target.value);
          }}
          id="options"
          form="options"
        >
          {menuSettings.selectOptions.map((option, index) => (
            <option key={index} value={option.value}>
              {option.option}
            </option>
          ))}
        </select>
      )}      

      <div className="globalcontextmenu__button-container">
        {menuSettings.cancelText && 
        <button onClick={handleCloseContextMenu}>
          {menuSettings?.cancelText}
        </button>}
        <button onClick={handleClickOnAgree}>
          {menuSettings?.agreeText}
        </button>
      </div>
    </>
  );
};


