import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { AudioContext } from "../AudioPlayer";

// Menü des Audioplayers
const AudioBrowserMenu = () => {
  const { currentSongOrigin } = useContext(AudioContext);

  return (
    <div className="audio-browser__menu">
      <img className="audio-browser__menu-logo" src="/files/images/logo.svg" />
      <NavLink className="audio-browser__menulink xl" to={"/suche"}>
        {SearchIcon}
        <b>Suche</b>
      </NavLink>
      <NavLink className="audio-browser__menulink" to={"/playlisten"}>
        Playlisten
      </NavLink>
      <NavLink className="audio-browser__menulink" to={"/gespeichert"}>
        Eigene Playlisten
      </NavLink>
      <NavLink className="audio-browser__menulink" to={"/impressum"}>
        Impressum
      </NavLink>

      {currentSongOrigin?.id !== "search" && currentSongOrigin?.id ? (
        <NavLink className="audio-browser__menulink" to={"/aktuell"}>
          Aktuelle Playlist
        </NavLink>
      ) : (
        ""
      )}
    </div>
  );
};

export default AudioBrowserMenu;

const SearchIcon = (
  <svg widths="20" height="20" fill="white" viewBox="0 0 24 24">
    <path d="M23.822 20.88l-6.353-6.354c.93-1.465 1.467-3.2 1.467-5.059.001-5.219-4.247-9.467-9.468-9.467s-9.468 4.248-9.468 9.468c0 5.221 4.247 9.469 9.468 9.469 1.768 0 3.421-.487 4.839-1.333l6.396 6.396 3.119-3.12zm-20.294-11.412c0-3.273 2.665-5.938 5.939-5.938 3.275 0 5.94 2.664 5.94 5.938 0 3.275-2.665 5.939-5.94 5.939-3.274 0-5.939-2.664-5.939-5.939z" />
  </svg>
);
