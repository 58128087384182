import React from "react";

// Listenüberschrift für "AudioBrowserSongList"
const SongListColumnHeading = ({ sortAfter, sortField, handleSort, sortAsc }) => {
  return (
    <div className={`song-item__text song__${sortAfter.replace(".", "")}`} onClick={() => handleSort(sortAfter)}>
      <span className="song-item__span">{sortAfter}</span>
      {sortField == sortAfter ? (
        <span className="song-item__arrow" style={{ transform: !sortAsc ? "rotate(180deg)" : "" }}>
          {sortArrow}
        </span>
      ) : (
        ""
      )}
    </div>
  );
};

export default SongListColumnHeading;

const sortArrow = (
  <svg version="1.1" viewBox="0 0 35.1 24.6" width="8" height="8" fillRule="evenodd" fill="white">
    <path
      d="M34.6,4.3c0.4-0.5,0.6-1.1,0.6-1.6c0-1.4-1.1-2.7-2.6-2.7H2.6C1.1,0,0,1.3,0,2.7c0,0.6,0.2,1.1,0.6,1.6
	C4.8,9.7,12,19,15.5,23.6c0.5,0.6,1.3,1,2.1,1c0.8,0,1.6-0.4,2.1-1C23.2,19,30.4,9.7,34.6,4.3z"
    />
  </svg>
);
