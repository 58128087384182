import { songs } from "data/data";
import React from "react";

// Impressum-Seite
const AudioBrowserLegalPage = () => {
  return (
    <div>
      <h1>Impressum und Credits</h1>
      <h2>Angaben gemäß § 5 TMG</h2>
      <p>Dieser Audioplayer wurde im Zuge meiner Bachelorarbeit entwickelt. Daher handelt es sich bei dieser Webseite um ein rein privates Projekt, für das keine Impressumspflicht besteht.</p>

      <div>
        <h2>Playlist-Coverbilder: Alle verwendeten Cover Bilder stammen von pixabay.com. Die Werke werden hier mit den Autorennamen und einem Link aufgelistet:</h2>
        <table className="legalpage-table">
          <thead>
            <tr>
              <th>Autor</th>
              <th>Werk</th>
              <th>URL</th>
            </tr>
          </thead>
          <tbody>
            {usedPlaylistCovers.map((element,index) => (
              <tr key={index}>
                <td>{element.Autor}</td>
                <td>{element.bildid}</td>
                <td>
                  <a href={element.url}>{element.url}</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <h2>Song-Coverbilder: Alle verwendeten Cover Bilder stammen von pixabay.com. Die Werke werden hier mit den Autorennamen und einem Link aufgelistet:</h2>
        <table className="legalpage-table">
          <thead>
            <tr>
              <th>Autor</th>
              <th>Werk</th>
              <th>URL</th>
            </tr>
          </thead>
          <tbody>
            {usedSongCovers.map((element, index) => (
              <tr key={index}>
                <td>{element.bildautor}</td>
                <td>{element.bildid}</td>
                <td>
                  <a href={element.url}>{element.url}</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <h2>Songs: Alle verwendeten Songs stammen von pixabay.com. Die Werke werden hier mit den Autorennamen und einem Link aufgelistet:</h2>
        <table className="legalpage-table">
          <thead>
            <tr>
              <th>Autor</th>
              <th>Werk</th>
              <th>URL</th>
            </tr>
          </thead>
          <tbody>
            {songs.list.map((element, index) => (
              <tr key={index}>
                <td>{element.interpret}</td>
                <td>{element.titel}</td>
                <td>
                  <a href={element.url}>{element.url}</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <h2>Icons: Alle verwendeten Icons stammen von iconmonstr.com. Daher ist iconmonstr.com der Urheber und Autor all dieser Icons:</h2>
        <ul className="legalpage-list">
          {usedIconsUrl.map((url, index) => (
            <li key={index}>
              <a href={url}>{url}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AudioBrowserLegalPage;

const usedIconsUrl = ["https://iconmonstr.com/x-mark-lined-svg/", "https://iconmonstr.com/magnifier-2-svg/", "https://iconmonstr.com/video-13-svg/", "https://iconmonstr.com/media-control-48-svg/", "https://iconmonstr.com/plus-5-svg/", "https://iconmonstr.com/minus-5-svg/", "https://iconmonstr.com/media-control-49-svg/", "https://iconmonstr.com/media-control-53-svg/", "https://iconmonstr.com/media-control-52-svg/", "https://iconmonstr.com/sound-wave-1-svg/"];

const usedPlaylistCovers = [
  {
    Autor: "javierdumont",
    Werk: "dj-vinyl-musik-drehscheibe-audio-4702977",
    url: "https://pixabay.com/de/photos/dj-vinyl-musik-drehscheibe-audio-4702977/",
    bildid: 4702977,
  },
  {
    Autor: "1195798",
    Werk: "gitarre-musikinstrument-2276181",
    url: "https://pixabay.com/de/photos/gitarre-musikinstrument-2276181/",
    bildid: 2276181,
  },
  {
    Autor: "Dimhou",
    Werk: "musik-dj-audio-klang-ausr%C3%BCstung-3569417",
    url: "https://pixabay.com/de/photos/musik-dj-audio-klang-ausr%C3%BCstung-3569417/",
    bildid: 3569417,
  },
  {
    Autor: "ktphotography",
    Werk: "konzert-konfetti-feier-2527495",
    url: "https://pixabay.com/de/photos/konzert-konfetti-feier-2527495/",
    bildid: 2527495,
  },
  {
    Autor: "quangle",
    Werk: "sonnenaufgang-boot-ruderboot-1014712",
    url: "https://pixabay.com/de/photos/sonnenaufgang-boot-ruderboot-1014712/",
    bildid: 1014712,
  },
];

const usedSongCovers = [
  {
    bildid: "1868612",
    bildtags: "headphones, listening, music",
    dateiname: "1868612.jpg",
    url: "https://pixabay.com/photos/headphones-listening-music-sound-1868612/",
    abrufdatum: "2023-07-14T03:05:07.881Z",
    bildautor: "Pexels",
  },
  {
    bildid: "1844798",
    bildtags: "audio, microphone, condenser microphone",
    dateiname: "1844798.jpg",
    url: "https://pixabay.com/photos/audio-microphone-1844798/",
    abrufdatum: "2023-07-14T03:05:08.264Z",
    bildautor: "Pexels",
  },
  {
    bildid: "5046876",
    bildtags: "microphone, headphones, music",
    dateiname: "5046876.jpg",
    url: "https://pixabay.com/photos/microphone-headphones-music-mic-5046876/",
    abrufdatum: "2023-07-14T03:05:08.588Z",
    bildautor: "AndNowProjekt",
  },
  {
    bildid: "1867121",
    bildtags: "mic, headphones, microphone",
    dateiname: "1867121.jpg",
    url: "https://pixabay.com/photos/mic-headphones-microphone-1867121/",
    abrufdatum: "2023-07-14T03:05:08.935Z",
    bildautor: "Pexels",
  },
  {
    bildid: "4945407",
    bildtags: "forest, march, morning",
    dateiname: "4945407.jpg",
    url: "https://pixabay.com/photos/forest-march-morning-4945407/",
    abrufdatum: "2023-07-14T03:05:10.842Z",
    bildautor: "pasja1000",
  },
  {
    bildid: "7583415",
    bildtags: "río, agua, al aire libre",
    dateiname: "7583415.jpg",
    url: "https://pixabay.com/photos/r%C3%ADo-agua-al-aire-libre-naturaleza-7583415/",
    abrufdatum: "2023-07-14T03:05:11.229Z",
    bildautor: "Leolo212",
  },
  {
    bildid: "756326",
    bildtags: "guitar, guitarist, music",
    dateiname: "756326.jpg",
    url: "https://pixabay.com/photos/guitar-guitarist-music-756326/",
    abrufdatum: "2023-07-14T03:05:11.838Z",
    bildautor: "Firmbee",
  },
  {
    bildid: "388989",
    bildtags: "tuba, music store, musical instrument",
    dateiname: "388989.jpg",
    url: "https://pixabay.com/photos/tuba-music-store-musical-instrument-388989/",
    abrufdatum: "2023-07-14T03:05:12.180Z",
    bildautor: "RyanMcGuire",
  },
  {
    bildid: "1658887",
    bildtags: "musician, guitar, song",
    dateiname: "1658887.jpg",
    url: "https://pixabay.com/photos/musician-guitar-song-rock-jazz-1658887/",
    abrufdatum: "2023-07-14T03:05:12.564Z",
    bildautor: "SeppH",
  },
  {
    bildid: "3090204",
    bildtags: "music, jazz, flute",
    dateiname: "3090204.jpg",
    url: "https://pixabay.com/photos/music-jazz-flute-musician-3090204/",
    abrufdatum: "2023-07-14T03:05:13.273Z",
    bildautor: "brendageisse",
  },
  {
    bildid: "4118588",
    bildtags: "instrument, clarinet, music",
    dateiname: "4118588.jpg",
    url: "https://pixabay.com/photos/instrument-clarinet-music-jazz-4118588/",
    abrufdatum: "2023-07-14T03:05:13.616Z",
    bildautor: "BobMcEvoy",
  },
  {
    bildid: "1655558",
    bildtags: "piano, sheet music, music",
    dateiname: "1655558.jpg",
    url: "https://pixabay.com/photos/piano-sheet-music-music-keyboard-1655558/",
    abrufdatum: "2023-07-14T03:05:14.500Z",
    bildautor: "stevepb",
  },
  {
    bildid: "756326",
    bildtags: "guitar, guitarist, music",
    dateiname: "756326.jpg",
    url: "https://pixabay.com/photos/guitar-guitarist-music-756326/",
    abrufdatum: "2023-07-14T03:05:14.863Z",
    bildautor: "Firmbee",
  },
  {
    bildid: "1239729",
    bildtags: "piano, rose, yellow rose",
    dateiname: "1239729.jpg",
    url: "https://pixabay.com/photos/piano-rose-yellow-rose-old-piano-1239729/",
    abrufdatum: "2023-07-14T03:05:15.595Z",
    bildautor: "MartyNZ",
  },
  {
    bildid: "3206530",
    bildtags: "balloon, chinese lanterns, lantern",
    dateiname: "3206530.jpg",
    url: "https://pixabay.com/photos/balloon-chinese-lanterns-lantern-3206530/",
    abrufdatum: "2023-07-14T03:05:18.596Z",
    bildautor: "NoName_13",
  },
  {
    bildid: "2527495",
    bildtags: "concert, confetti, party",
    dateiname: "2527495.jpg",
    url: "https://pixabay.com/photos/concert-confetti-party-event-club-2527495/",
    abrufdatum: "2023-07-14T03:05:18.942Z",
    bildautor: "ktphotography",
  },
  {
    bildid: "3024434",
    bildtags: "poinsettia, flower wallpaper, christmas",
    dateiname: "3024434.jpg",
    url: "https://pixabay.com/photos/poinsettia-flower-wallpaper-3024434/",
    abrufdatum: "2023-07-14T03:05:20.332Z",
    bildautor: "TerriC",
  },
  {
    bildid: "1655558",
    bildtags: "piano, sheet music, music",
    dateiname: "1655558.jpg",
    url: "https://pixabay.com/photos/piano-sheet-music-music-keyboard-1655558/",
    abrufdatum: "2023-07-14T03:05:20.910Z",
    bildautor: "stevepb",
  },
  {
    bildid: "756326",
    bildtags: "guitar, guitarist, music",
    dateiname: "756326.jpg",
    url: "https://pixabay.com/photos/guitar-guitarist-music-756326/",
    abrufdatum: "2023-07-14T03:05:22.421Z",
    bildautor: "Firmbee",
  },
  {
    bildid: "3021072",
    bildtags: "polynesia, french polynesia, tahiti",
    dateiname: "3021072.jpg",
    url: "https://pixabay.com/photos/polynesia-french-polynesia-tahiti-3021072/",
    abrufdatum: "2023-07-14T03:05:23.392Z",
    bildautor: "Julius_Silver",
  },
  {
    bildid: "2040340",
    bildtags: "stones, rocks, stack",
    dateiname: "2040340.jpg",
    url: "https://pixabay.com/photos/stones-rocks-stack-pebbles-2040340/",
    abrufdatum: "2023-07-14T03:05:24.482Z",
    bildautor: "Schäferle",
  },
  {
    bildid: "2836300",
    bildtags: "beach, sun, evening",
    dateiname: "2836300.jpg",
    url: "https://pixabay.com/photos/beach-sun-evening-summer-sea-2836300/",
    abrufdatum: "2023-07-14T03:05:25.208Z",
    bildautor: "Yeskay1211",
  },
  {
    bildid: "2527495",
    bildtags: "concert, confetti, party",
    dateiname: "2527495.jpg",
    url: "https://pixabay.com/photos/concert-confetti-party-event-club-2527495/",
    abrufdatum: "2023-07-14T03:05:26.212Z",
    bildautor: "ktphotography",
  },
  {
    bildid: "2695569",
    bildtags: "milky way, hd wallpaper, wallpaper 4k",
    dateiname: "2695569.jpg",
    url: "https://pixabay.com/photos/milky-way-hd-wallpaper-wallpaper-4k-2695569/",
    abrufdatum: "2023-07-14T03:05:27.825Z",
    bildautor: "FelixMittermeier",
  },
  {
    bildid: "1867616",
    bildtags: "astronomy, hd wallpaper, bright",
    dateiname: "1867616.jpg",
    url: "https://pixabay.com/photos/astronomy-hd-wallpaper-bright-1867616/",
    abrufdatum: "2023-07-14T03:05:28.154Z",
    bildautor: "Pexels",
  },
  {
    bildid: "3608029",
    bildtags: "galaxy, free wallpaper, star",
    dateiname: "3608029.jpg",
    url: "https://pixabay.com/photos/galaxy-free-wallpaper-star-3608029/",
    abrufdatum: "2023-07-14T03:05:28.906Z",
    bildautor: "Luminas_Art",
  },
  {
    bildid: "11107",
    bildtags: "orion nebula, emission nebula, constellation orion",
    dateiname: "11107.jpg",
    url: "https://pixabay.com/photos/orion-nebula-emission-nebula-11107/",
    abrufdatum: "2023-07-14T03:05:29.269Z",
    bildautor: "WikiImages",
  },
];
