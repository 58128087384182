import React, { useEffect } from "react";
import PlayListItem from "./PlaylistItem";

// Komponente zum Ausgeben von Playlisten
const AudioBrowserPlaylistList = ({ playlisten }) => {
  useEffect(() => {}, []);

  return (
    <>
      <div className="playlist-list">
        {playlisten.map((playlist, index) => (
          <PlayListItem key={index} playlist={playlist} />
        ))}
      </div>
    </>
  );
};

export default AudioBrowserPlaylistList;
