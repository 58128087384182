import React, { useContext } from "react";
import { AudioContext } from "components/AudioPlayer/AudioPlayer";
import { formatSecondsToTimecode } from "lib/helpers";
import { UserDataContext } from "context/UserDataContext";
import { ContextMenuContext } from "components/GlobalContextMenu/GlobalContextMenu";

// SongListItem ist Bestandteil der AudioBrowserSongList
const SongListItem = ({ positionIndex, playlistData, song, activePlaylist }) => {
  const { handleOpenContextMenu } = useContext(ContextMenuContext);
  const { handleAddSongIntoPlaylist, handleDeleteSongInPlaylist, personalPlaylists } = useContext(UserDataContext);
  const { currentSongOrigin, setCurrentSongOrigin, handleSongStart, currentSong } = useContext(AudioContext);

  const handlePressAddSong = () => {
    const selectInputValues = personalPlaylists.map((element) => {
      let selectOption = { option: element.name, value: element.id };
      return selectOption;
    });

    if (selectInputValues.length > 0) {
      handleOpenContextMenu({
        heading: "Song hinzufügen",
        description: "Wählen Sie eine Playlist aus die den Song hinzugefügt bekommen soll.",
        cancelText: "Abbrechen",
        agreeText: "Song hinzufügen",
        agreeFunction: handleAddSongIntoPlaylist,
        agreeFunctionParams: [song?.id],
        selectOptions: selectInputValues,
        selectOptionsParamPosition: 1,
        type: "select",
      });
    } else {
      handleOpenContextMenu({
        heading: "Du hast noch keine Playlist erstellt.",
        description: "Erstelle zuerst eine Playlist um diesen Song einer Playlist hinzuzufügen.",
        agreeText: "Song hinzufügen",
        type: "simple",
      });
    }
  };

  const handlePressDeleteSong = () => {
    handleOpenContextMenu({
      heading: "Song entfernen",
      description: `Möchten Sie den Song namens: ${song.titel} wirklich aus ihrer Playlist löschen?`,
      cancelText: "Abbrechen",
      agreeText: "Song entfernen",
      agreeFunction: handleDeleteSongInPlaylist,
      agreeFunctionParams: [song?.playlistIndex, playlistData.id],
      type: "simple",
    });
  };

  const handlePressPlay = () => {
    song.currentPlaylistIndex = positionIndex;

    handleSongStart(song, activePlaylist);
    setCurrentSongOrigin(playlistData);
  };

  return (
    <div onDoubleClick={handlePressPlay} className={`song-item ${currentSong?.id === song?.id && currentSongOrigin?.id == playlistData?.id && currentSong.playlistIndex == song.playlistIndex ? "active" : ""}`}>
      <div className="song-item__controls song__Controls">
        <div className="song-item__button" onClick={handlePressPlay}>
          {playbutton}
        </div>
        <div className="song-item__button" onClick={handlePressAddSong}>
          {addButton}
        </div>
        {playlistData.privat === true && (
          <div className="song-item__button" onClick={handlePressDeleteSong}>
            {deleteButton}
          </div>
        )}
      </div>
      <div className="song__Cover">
        <img className="song-item__cover" src={song?.cover ? `/files/covers/${song.cover}` : "/files/images/nocover.jpg"} />
      </div>
      <div className="song-item__text song__Songtitel">
        <span className="song-item__span">{song?.titel ? song.titel : "Song konnte nicht geladen werden."} </span>
      </div>
      <div className="song-item__text song__Interpret">
        <span className="song-item__span">{song?.interpret}</span>
      </div>
      <div className="song-item__text song__Songdauer">
        <span className="song-item__span">{formatSecondsToTimecode(song?.songdauer)}</span>
      </div>
      <div className="song-item__text song__Genre">
        <span className="song-item__span">{song?.genre}</span>
      </div>
      <div className="song-item__text song__Nr">
        <span className="song-item__span">{song.playlistIndex}</span>
      </div>
    </div>
  );
};

export default SongListItem;

const playbutton = (
  <div className="button-container__inner">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="white">
      <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-3 17v-10l9 5.146-9 4.854z" />
    </svg>
  </div>
);

const addButton = (
  <div className="button-container__inner">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="white">
      <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-5v5h-2v-5h-5v-2h5v-5h2v5h5v2z" />
    </svg>
  </div>
);

const deleteButton = (
  <div className="button-container__inner">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="white">
      <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 13h-12v-2h12v2z" />
    </svg>
  </div>
);
