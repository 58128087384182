import React, { useContext } from "react";
import { AudioContext } from "../AudioPlayer";
import { Navigate, Route, Routes } from "react-router-dom";
import AudioBrowserPlaylistPage from "./AudioBrowserContentPages/AudioBrowserPlaylistPage";
import AudioBrowserSearchPage from "./AudioBrowserContentPages/AudioBrowserSearchPage";
import AudioBrowserPersonalPlaylistsPage from "./AudioBrowserContentPages/AudioBrowserPersonalPlaylistsPage";
import AudioBrowserAddPlaylistPage from "./AudioBrowserContentPages/AudioBrowserAddPlaylistPage";
import AudioBrowserPublicPlaylistsPage from "./AudioBrowserContentPages/AudioBrowserPublicPlaylistsPage";
import AudioBrowserLegalPage from "./AudioBrowserContentPages/AudioBrowserLegalPage";

// Verwaltung der Routen und ausgegebenen Seiten
const AudioBrowserContentPage = () => {
  const { currentSongOrigin } = useContext(AudioContext);

  return (
    <div className="audio-browser__content">
      <Routes>
        <Route path="/playlist" element={<Navigate to="/playlisten" />} />
        <Route path="/playlist/:playlistid" element={<AudioBrowserPlaylistPage />} />
        <Route path="/playlist-hinzufuegen" element={<AudioBrowserAddPlaylistPage />} />
        <Route path="/playlisten" element={<AudioBrowserPublicPlaylistsPage />} />
        <Route path="/impressum" element={<AudioBrowserLegalPage />} />
        <Route path="/gespeichert" element={<AudioBrowserPersonalPlaylistsPage />} />
        <Route path="/suche" element={<AudioBrowserSearchPage />} />
        <Route path="/aktuell" element={currentSongOrigin?.id !== "search" ? <Navigate to={`/playlist/${currentSongOrigin?.id}`} /> : <Navigate to={`/`} />} />
        <Route path="/*" element={<Navigate to="playlisten" />} />
      </Routes>
    </div>
  );
};

export default AudioBrowserContentPage;
