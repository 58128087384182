import AudioBrowserMenu from "../AudioBrowserComponents/AudioBrowserMenu";
import AudioBrowserContentPage from "../AudioBrowserComponents/AudioBrowserContentPage";

const AudioBrowser = () => {
  return (
    <div className="audio-browser">
      <AudioBrowserMenu></AudioBrowserMenu>
      <AudioBrowserContentPage></AudioBrowserContentPage>
    </div>
  );
};

export default AudioBrowser;
