import React, { useEffect, useState } from "react";

export const UserDataContext = React.createContext();

// Ermöglicht den Zugriff auf die eigenen Playlisten
function UserDataProvider({ children }) {
  const [personalPlaylists, setPersonalPlaylists] = useState([]);
  const [loadingFinished, setLoadingFinished] = useState(false);

  // Laden der im "localstorage" existierenden Playlisten
  useEffect(() => {
    let savedPlaylistsJson = localStorage.getItem("personalPlaylists");

    if (savedPlaylistsJson) {
      let savedPlaylists = JSON.parse(savedPlaylistsJson);
      setPersonalPlaylists(savedPlaylists);
    } else {
      setPersonalPlaylists([{ color: "#FF00FF", id: 5005, name: "Beispielplaylist", cover: "nocover.jpg", list: [1034, 1041, 1006, 1012, 1013, 1004, 1034, 1032], privat: true }]);
    }
  }, []);

  // Speichern der Playlisten im "localstorage" bei Veränderung dieser
  useEffect(() => {
    const currentData = personalPlaylists;

    if (Array.isArray(currentData) && currentData.length > 0) {
      setLoadingFinished(true);
      localStorage.setItem("personalPlaylists", JSON.stringify(currentData));
    }
  }, [personalPlaylists]);

  const handleDeleteSongInPlaylist = (playlistPosition, playlistId) => {
    const findPlaylist = personalPlaylists.find((element) => {
      return element.id == playlistId;
    });

    const currentPlaylistPosition = personalPlaylists.indexOf(findPlaylist);
    const duplicatedPersonalPlaylists = [...personalPlaylists];

    const newPlaylistObject = Object.assign({}, findPlaylist);
    newPlaylistObject.list.splice(parseInt(playlistPosition) - 1, 1);

    duplicatedPersonalPlaylists.splice(currentPlaylistPosition, 1, newPlaylistObject);
    setPersonalPlaylists(duplicatedPersonalPlaylists);
  };

  const handleAddSongIntoPlaylist = (songid, playlistid) => {
    const updatedList = personalPlaylists.map((element) => {
      if (parseInt(element.id) == parseInt(playlistid)) {
        const newElement = Object.assign({}, element);
        newElement.list.push(songid);

        return newElement;
      } else {
        return element;
      }
    });

    setPersonalPlaylists(updatedList);
  };

  const handleAddPlaylist = (playlistName, playlistCover, playlistCoverColor) => {
    let highestId = 5000;
    let playlistWithHighestId;

    if (personalPlaylists?.length > 0) {
      playlistWithHighestId = personalPlaylists.reduce((maxObject, currentObject) => {
        return maxObject.id > currentObject.id ? maxObject : currentObject;
      });

      highestId = playlistWithHighestId.id;
    }

    const duplicatedPersonalPlaylists = [...personalPlaylists];
    duplicatedPersonalPlaylists.push({
      id: parseInt(highestId) + 1,
      name: playlistName,
      cover: playlistCover,
      color: playlistCoverColor,
      privat: true,
      list: [],
    });

    setPersonalPlaylists(duplicatedPersonalPlaylists);
  };

  const handleDeletePlaylist = (playlistid) => {
    const duplicatedPersonalPlaylists = [...personalPlaylists];
    const foundElement = duplicatedPersonalPlaylists.find((element) => {
      return parseInt(element.id) == parseInt(playlistid);
    });

    const playlistPosition = duplicatedPersonalPlaylists.indexOf(foundElement);
    duplicatedPersonalPlaylists.splice(playlistPosition, 1);

    setPersonalPlaylists(duplicatedPersonalPlaylists);
  };

  return (
    <UserDataContext.Provider
      value={{
        personalPlaylists,
        setPersonalPlaylists,
        handleDeleteSongInPlaylist,
        handleAddSongIntoPlaylist,
        handleAddPlaylist,
        handleDeletePlaylist,
      }}
    >
      {loadingFinished ? children : "Laden..."}
    </UserDataContext.Provider>
  );
}

export default UserDataProvider;
