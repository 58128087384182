import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserDataContext } from "context/UserDataContext";
import { ContextMenuContext } from "components/GlobalContextMenu/GlobalContextMenu";

// PlaylistItem ist Bestandteil der AudioBrowserPlaylistList
const PlayListItem = ({ playlist }) => {
  const navigate = useNavigate();
  const { handleDeletePlaylist } = useContext(UserDataContext);
  const { handleOpenContextMenu } = useContext(ContextMenuContext);

  const handleDeleteButton = (event) => {
    handleOpenContextMenu({
      heading: "Playlist löschen?",
      description: `Möchten Sie die Playlist mit dem Namen: ${playlist.name} wirklich löschen?`,
      cancelText: "Abbrechen",
      agreeText: "Playlist löschen",
      agreeFunction: handleDeletePlaylist,
      agreeFunctionParams: [playlist.id],
      type: "simple",
    });
  };

  const handleOnClick = (event) => {
    navigate(`/playlist/${playlist.id}`);
  };

  return (
    <>
      <div className={"playlist-item"}>
        {playlist.privat === true && (
          <div>
            <button onClick={handleDeleteButton}>Löschen</button>
          </div>
        )}
        <img src={playlist?.cover ? `files/images/${playlist.cover}` : `/files/images/nocover.jpg`} className="playlist-item__image"></img>
        <div style={{ backgroundColor: playlist.color }} className="playlist-item__overlay"></div>
        <div onClick={handleOnClick} className="playlist-item__gradient"></div>
        <div className={`playlist-item__inner`}>
          {playlist.privat === true && (
            <button className="playlist-item__button" onClick={handleDeleteButton}>
              {playlistDeleteButton}
            </button>
          )}
          <div className="playlist_item__title">{playlist.name}</div>
        </div>
      </div>
    </>
  );
};

export default PlayListItem;

const playlistDeleteButton = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="white">
    <path d="m12 10.93 5.719-5.72c.146-.146.339-.219.531-.219.404 0 .75.324.75.749 0 .193-.073.385-.219.532l-5.72 5.719 5.719 5.719c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.385-.073-.531-.219l-5.719-5.719-5.719 5.719c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l5.719-5.719-5.72-5.719c-.146-.147-.219-.339-.219-.532 0-.425.346-.749.75-.749.192 0 .385.073.531.219z" />
  </svg>
);
