import React, { useState } from "react";
import { playlists } from "data/data";
import AudioBrowserPlaylistList from "../SubComponents/AudioBrowserPlaylistList";

// Vordefinierte-Playlisten-Seite
const AudioBrowserPublicPlaylistsPage = () => {
  const [publicPlaylists] = useState(playlists.list);

  return (
    <>
      <div>
        <h1>Vordefinierte Playlisten</h1>
        <p className="audio-browser_description">Hier befinden sich vordefinierte Playlisten. </p>
      </div>
      
      <AudioBrowserPlaylistList playlisten={publicPlaylists} />
    </>
  );
};

export default AudioBrowserPublicPlaylistsPage;
