import React, { useState, useRef, useEffect } from "react";
import AudioControl from "./AudioControlComponents/AudioControl";
import AudioBrowser from "./AudioBrowserComponents/AudioBrowser";

export const AudioContext = React.createContext();

// Steuerung der Audioinhalte
const AudioPlayer = () => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [volume, setVolume] = useState(0.25);

  const [currentSong, setCurrentSong] = useState();
  const [currentPlaylist, setCurrentPlaylist] = useState([]);
  const [currentPlaylistIndex, setCurrentPlaylistIndex] = useState();
  const [currentSongOrigin, setCurrentSongOrigin] = useState();
  const [currentPlaybackTime, setCurrentPlaybackTime] = useState(0);
  const [currentSongDuration, setCurrentSongDuration] = useState(0);

  // Abspielen des Songs, sofern "isPlaying" ist TRUE und "isLoading" ist FALSE
  useEffect(() => {
    if (isPlaying && !isLoading) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying, isLoading, currentSong]);

  // Lautstärke anpassen, sofern diese sich verändert
  useEffect(() => {
    audioRef.current.volume = volume;
  }, [volume]);

  const handleVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
  };

  const handleSongFinished = () => {
    handleNavigate("next");
  };

  const handleNavigate = (direction) => {
    let newSongIndex;

    if (!currentPlaylist || !currentSong) {
      return;
    }

    if (direction === "back") {
      newSongIndex = (currentPlaylistIndex - 1 + currentPlaylist.length) % currentPlaylist.length;
      handleSongStart(currentPlaylist[newSongIndex]);
      setCurrentPlaylistIndex(newSongIndex);
    } else if (direction === "next") {
      newSongIndex = (currentPlaylistIndex + 1 + currentPlaylist.length) % currentPlaylist.length;
      handleSongStart(currentPlaylist[newSongIndex]);
      setCurrentPlaylistIndex(newSongIndex);
    }
  };

  const handleCurrentPlaylistUpdate = (currentPlaylistNew) => {
    const activeElement = currentPlaylistNew.find((element) => parseFloat(element.playlistIndex) === parseFloat(currentSong.playlistIndex));
    const currentSongIndexInCurrentPlaylist = currentPlaylistNew.indexOf(activeElement);

    setCurrentPlaylist(currentPlaylistNew);
    setCurrentPlaylistIndex(currentSongIndexInCurrentPlaylist);
  };

  const handleSongStart = (song, currentPlaylist) => {
    if (currentPlaylist) {
      setCurrentPlaylistIndex(song.currentPlaylistIndex);
      setCurrentPlaylist(currentPlaylist);
    }

    if (currentSong?.id !== song.id && currentSong?.dateipfad !== song.dateipfad) {
      setIsLoading(true);
    }

    const newSong = Object.assign({}, song)
    setCurrentSong(newSong);
    handleTimeChange(0);
    setIsPlaying(true);
  };

  const handlePlayPause = () => {
    if (currentSong) {
      setIsPlaying(!isPlaying);
    }
  };

  const handleMetaDataLoaded = (event) => {
    setCurrentSongDuration(event.target.duration);
  };

  const handleSongLoaded = (event) => {
    setIsLoading(false);
  };

  const handleTimeChange = (time) => {
    audioRef.current.currentTime = time;
  }

  return (
    <AudioContext.Provider
      value={{
        audioRef,
        handleCurrentPlaylistUpdate,
        handleSongFinished,
        handleNavigate,
        handleSongStart,
        handleVolumeChange,
        handlePlayPause,
        handleTimeChange,
        currentPlaybackTime,
        setCurrentPlaybackTime,
        currentPlaylistIndex,
        setCurrentPlaylistIndex,
        currentSongOrigin,
        setCurrentSongOrigin,
        isPlaying,
        setIsPlaying,
        currentPlaylist,
        setCurrentPlaylist,
        currentSong,
        setCurrentSong,
        volume,
        setVolume,
        currentSongDuration,
        setCurrentSongDuration,
      }}
    >
      <div id="wrapper">
        <audio 
        src={`/files/music/${currentSong?.dateipfad}`} 
        ref={audioRef} 
        onLoadedMetadata={handleMetaDataLoaded} 
        onLoadedData={handleSongLoaded} 
        onEnded={handleSongFinished}
        onTimeUpdate={(event) => {setCurrentPlaybackTime(event.currentTime)}}
        />

        <AudioBrowser></AudioBrowser>
        <AudioControl></AudioControl>
      </div>
    </AudioContext.Provider>
  );
};

export default AudioPlayer;