import React, { useState, useRef, useEffect, useContext } from "react";
import { AudioContext } from "../AudioPlayer";
import { formatSecondsToTimecode } from "lib/helpers";

// Zeitleiste für AudioControl
const AudioControlTimeline = ({}) => {
  const { audioRef, currentSongDuration, handleTimeChange } = useContext(AudioContext);

  const [isDragging, setIsDragging] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const timelineRef = useRef(null);

  // Synchronisation des Abspielstatus mit der Zeitleiste
  useEffect(() => {
    const setCurrentTimeFunction = () => {
      setCurrentTime(audioRef.current.currentTime);
    };

    audioRef.current.addEventListener("timeupdate", setCurrentTimeFunction);

    return () => {
      audioRef.current.removeEventListener("timeupdate", setCurrentTimeFunction);
    };
  }, []);

   // Verwaltung des Scrollens in der Zeitleiste
  useEffect(() => {
    if (isDragging) {
      document.body.style.userSelect = "none";
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      document.body.style.userSelect = "";
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.body.style.userSelect = "";
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;

    const timelineWidth = timelineRef.current.offsetWidth;
    const clickPosition = event.pageX - timelineRef.current.getBoundingClientRect().left;
    const clickTime = (clickPosition / timelineWidth) * currentSongDuration;
    handleTimeChange(clickTime);
  };

  const handleTimelineClick = (event) => {
    const timelineWidth = timelineRef.current.offsetWidth;
    const clickPosition = event.pageX - timelineRef.current.getBoundingClientRect().left;
    const clickTime = (clickPosition / timelineWidth) * currentSongDuration;
    handleTimeChange(clickTime);
  };

  const calculateProgress = () => {
    if (!timelineRef.current) return "0%";

    const timelineWidth = timelineRef.current.offsetWidth;
    const position = (currentTime / currentSongDuration) * timelineWidth;
    return `${(position / timelineWidth) * 100}%`;
  };

  return (
    <>
      <div 
      className={`timeline ${isDragging || isHovering ? "active" : ""}`} 
      ref={timelineRef} 
      onClick={handleTimelineClick} 
      onMouseDown={handleMouseDown} 
      onMouseUp={handleMouseUp} 
      onMouseMove={handleMouseMove} 
      onMouseEnter={handleMouseEnter} 
      onMouseLeave={handleMouseLeave}
      >
        <div className="timeline__progress" style={{ width: calculateProgress() }}></div>
        <div className="timeline__circle" style={{ left: calculateProgress() }}></div>
      </div>
      <div className="timeline__timer">{`${formatSecondsToTimecode(currentTime)} / ${formatSecondsToTimecode(currentSongDuration)}`}</div>
    </>
  );
};

export default AudioControlTimeline;
