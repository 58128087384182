import React, { useState } from "react";
import AudioBrowserSongList from "../SubComponents/AudioBrowserSongList";
import { songs, playlists } from "data/data";
import NoEntryMessage from "../SubComponents/NoEntryMessage";
import AudioBrowserPlaylistList from "../SubComponents/AudioBrowserPlaylistList";

// Suchen-Seite
const AudioBrowserSearchPage = () => {
  const [search, setSearch] = useState("");
  const [filteredSongs, setFilteredSongs] = useState([]);
  const [filteredPlaylist, setFilteredPlaylist] = useState([]);

  const handleChange = (event) => {
    setSearch(event.target.value);

    // Playlistsuche
    if (event.target.value !== "") {
      const results = playlists.list.filter((playlist) => {
        return playlist.name.toLowerCase().includes(event.target.value.toLowerCase());
      });
      setFilteredPlaylist(results);
    } else {
      setFilteredPlaylist([]);
    }

    // Songsuche
    if (event.target.value !== "") {
      const results = songs.list.filter((song) => {
        return song.titel.toLowerCase().includes(event.target.value.toLowerCase()) || song.interpret.toLowerCase().includes(event.target.value.toLowerCase()) || song.genre.toLowerCase().includes(event.target.value.toLowerCase());
      });
      setFilteredSongs(results);
    } else {
      setFilteredSongs();
    }
  };

  return (
    <div>
      <div className="search-input__container">
        <h1>Song- und Playlistsuche</h1>
        <input type="text" placeholder="z.B. Rock..." value={search} onChange={handleChange} />
      </div>

      <div>
        {filteredSongs?.length > 0 && Array.isArray(filteredSongs) && (
          <>
            <h1>Songs:</h1>
            <AudioBrowserSongList playlistData={{ id: "search" }} playlistSongs={filteredSongs}></AudioBrowserSongList>
          </>
        )}
      </div>

      {filteredPlaylist?.length > 0 && (
        <div>
          <h1>Playlisten:</h1>
          <AudioBrowserPlaylistList playlisten={filteredPlaylist} />
        </div>
      )}

      {((filteredSongs?.length == 0 && filteredPlaylist?.length == 0) || !Array.isArray(filteredSongs)) && (
        <NoEntryMessage>
          <h3 className="no-margin">{!search ? "Geben Sie einen Suchbegriff in die Suche ein um die Suche zu starten." : "Es wurde kein Eintrag gefunden."}</h3>
          <p> {!search ? "Suchen Sie nach einem Song, einer Playlist oder nach einem Künstler." : "Diese Suchanfrage hat keine Ergebnisse erzielt."}</p>
        </NoEntryMessage>
      )}
      
      <p style={{ color: "grey", fontSize: "0.8em" }}>Hinweis: Alle Bild-, Icon- und Musikwerke der Webanwendung wurden nicht von dem Autor dieser Arbeit erstellt und sind frei nutzbare Werke von Pixabay.com  und icon-monstr.com. Die Song-Cover wurden nachträglich hinzugefügt und sind nicht vom ursprünglichen Musik-Urheber ausgewählt worden. Eine Übersicht der Musik-, Icon- und Coverurheber sowie der Lizenztypen befindet sich unter dem Reiter Impressum. Außerdem erfolte die Zuweisung eines Genres im nachhinhein. Eine Auflistung finden Sie im Impressum.</p>
    </div>
  );
};

export default AudioBrowserSearchPage;
